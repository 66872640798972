import React, {useEffect, useState} from 'react';
import {DateTime, Select} from "finform";
import moment from 'moment/moment';
import BlockChartInnerTabsChart
  from "#app/pages/IncomeAndExpenses/Tactics/components/BlockChartInnerTabs/BlockChartInnerTabsChart";
import {Row} from "#app/pages/styles";
import {Title3} from "#app/components/Text/styles";
import {Api} from "laravel-request";
import {connect} from "react-redux";
import {
  getSpendingsWithFilter,
  tacticsSetBlockType,
  tacticsSetEnd,
  tacticsSetMonths,
  tacticsSetSelectedMonth,
  tacticsSetStart
} from "#app/actions/tactics";

export const BY_MONTH = 1;
export const BY_PERIOD = 2;

const TacticsFilter = ({ title, i18n, client, currency, tutorial, demo, start, end, blockType, months, selectedMonth, getSpendingsWithFilter, tacticsSetStart, tacticsSetEnd, tacticsSetBlockType, tacticsSetMonths, tacticsSetSelectedMonth }) => {
  const [prevClient, setPrevClient] = useState(null);

  const getMonths = (client, currency) => {
    Api.get('active', 'index', {
      currency_id: currency.id,
      user_id: client.id
    })
      .whereHas('income_account', query => query.where('is_visible', 1))
      .orderBy('buy_at', 'ASC')
      .first((response) => {
        const current = moment();
        let startMoment = response.data.buy_at_date ? moment(response.data.buy_at_date, 'DD.MM.YYYY') : moment().subtract(1, 'year');
        const diffMonths = Math.abs(startMoment.diff(current, 'months')) + 1;

        const newMonths = [];
        for (let i = 0; i <= diffMonths; i++) {
          const date = startMoment.clone().add(i, 'months');
          newMonths.push({ id: i, date: date.format('YYYY-MM-DD'), name: date.format('YYYY MMMM') });
        }

        tacticsSetMonths(newMonths);  // Сохранение в Redux и localStorage

        if (blockType === BY_MONTH && newMonths.length > 0) {
          const lastMonth = newMonths[newMonths.length - 1];

          // Устанавливаем последний доступный месяц в selectedMonth
          tacticsSetSelectedMonth(lastMonth);

          // Определяем начало и конец выбранного месяца
          const startOfMonth = moment(lastMonth.date).startOf('month').format('DD-MM-YYYY');
          const endOfMonth = moment(lastMonth.date).endOf('month').format('DD-MM-YYYY');

          // Устанавливаем start и end в Redux
          tacticsSetStart(startOfMonth);
          tacticsSetEnd(endOfMonth);
        }
        getSpendingsWithFilter(); // Вызов после обновления месяцев
      });
  };

  const handleBlockTypeChange = (blockType) => {
    tacticsSetBlockType(blockType);
  };

  const handleMonthChange = (selectedMonth) => {
    if(selectedMonth)
    {
      // Устанавливаем выбранный месяц в selectedMonth
      tacticsSetSelectedMonth(selectedMonth);

      // Определяем начало и конец выбранного месяца
      const startOfMonth = moment(selectedMonth.date).startOf('month').format('DD-MM-YYYY');
      const endOfMonth = moment(selectedMonth.date).endOf('month').format('DD-MM-YYYY');

      // Устанавливаем start и end в Redux
      tacticsSetStart(startOfMonth);
      tacticsSetEnd(endOfMonth);
    }
  };

  const handleStartDateChange = (value) => {

    tacticsSetStart(value);
    getSpendingsWithFilter(); // Вызов, если изменяются фильтры
  };

  const handleEndDateChange = (value) => {
    tacticsSetEnd(value);
    getSpendingsWithFilter(); // Вызов, если изменяются фильтры
  };

  useEffect(() => {
    if (client?.id && currency?.id) {
      if (client.id !== prevClient?.id) {
        getMonths(client, currency); // Обновляем месяца, если изменился клиент
        setPrevClient(client); // Обновляем предыдущего клиента
      } else {
        getSpendingsWithFilter(); // Если клиент не изменился, просто вызываем getSpendingsWithFilter
      }
    }
  }, [client, currency, start, end, blockType]);

  return (
    <Row>
      <Title3>{title}</Title3>
      <BlockChartInnerTabsChart style={{marginLeft: '15px'}} chartId={blockType} onClick={handleBlockTypeChange}/>
      {blockType === BY_MONTH && (
        <Select
          field='block_month'
          text={'Месяц'}
          defaultText={'Месяц'}
          disabled={false}
          selected={selectedMonth}
          containerStyle={{
            maxWidth: '177px',
            minWidth: '177px',
            margin: '15px 0 15px 15px',
          }}
          handle={handleMonthChange}
          items={months?.sort((a, b) => new Date(b.date) - new Date(a.date))}
        />
      )}

      {blockType === BY_PERIOD && (
        <div style={{ display: 'flex' }}>
          <DateTime
            containerStyle={{
              maxWidth: '103px',
              minWidth: '103px',
              margin: '0 0 0 15px',
            }}
            field='start'
            type="text"
            icon={false}
            disabled={false}
            value={start}
            onChangeDateInner={(e, { value }) => handleStartDateChange(value)}
            placeholder={i18n.incomeOutcome.from}
          />
          <DateTime
            containerStyle={{
              maxWidth: '103px',
              minWidth: '103px',
              margin: '0 0 0 15px',
            }}
            field='end'
            type="text"
            icon={false}
            disabled={false}
            value={end}
            onChangeDateInner={(e, { value }) => handleEndDateChange(value)}
            placeholder={i18n.incomeOutcome.to}
          />
        </div>
      )}
    </Row>
  );
};

const enhance = connect(
  (state) => ({
    client: state.interfaceComponents.client,
    currency: state.interfaceComponents.currency,
    accounts: state.interfaceComponents.accounts,
    spendTypes: state.interfaceComponents.spendTypes,
    i18n: state.i18n,
    tutorial: state.scenario.tutorial,
    start: state.tactics.start,
    end: state.tactics.end,
    blockType: state.tactics.blockType,
    months: state.tactics.months,
    selectedMonth: state.tactics.selectedMonth,
    demo: state.scenario.demo,
  }),
  { getSpendingsWithFilter, tacticsSetStart, tacticsSetEnd, tacticsSetBlockType, tacticsSetMonths, tacticsSetSelectedMonth }
)

export default enhance(TacticsFilter);