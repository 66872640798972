import styled from 'styled-components'


export const TabWrapper = styled.div`
    display: inline-flex;
    background-color: #EFF2F5;
    width: fit-content;
    border-radius: 12px;
    padding: 2px;
    user-select: none;
    height: 48px;
`

export const Container = styled.a`
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    color: #4378FF;
    font-size: 14px;
    margin-left: 3px;
    font-weight: 700;
    padding: 0 15px;
    line-height: 45px;
    
    &.style1{
      color: #36B2AB;
    }
    &.style2{
      color: #FF624D;
    }
    
    :first-child{
      margin-left: 0;
    }
    
    &.active{
      background-color: #fff;
      color: #000;
    }
    
    &.style1.active{
      color: #fff;
      background-color: #36B2AB;
    }
    
    &.style2.active{
      color: #fff;
      background-color: #FF624D;
    }
`
