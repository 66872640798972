import {combineReducers} from 'redux'
import auth from './auth'
import interfaceComponents from './interface'
import scenario from './scenario'
import plan from './plan'
import jobs from './jobs'
import tactics from './tactics'
import i18n from './i18n'

export default () => combineReducers({
  auth,
  interfaceComponents,
  scenario,
  plan,
  jobs,
  tactics,
  i18n,
})
