import React, {Suspense} from 'react';
import {
  AuthLayout,
  Browser,
  CallbackNotification,
  DemoSidebar,
  HeaderCabinet,
  Notification,
  Scenario,
  SidebarCabinet
} from './LazyLoadedPages';
import InvestsProvider from "#app/contexts/InvestsProvider";

const CabinetLayout = ({ isLoaded, client, demo, scenarioName }) => (
  <Suspense>
    <HeaderCabinet />
    {isLoaded && <InvestsProvider>
      {!!client && demo && scenarioName === 'demo' ? <DemoSidebar /> : <SidebarCabinet />}
    </InvestsProvider>}
    <AuthLayout />
    <Scenario />
    <Browser />
    <Notification />
    <CallbackNotification />
  </Suspense>
);

export default CabinetLayout;
