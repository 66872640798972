import React, {Component, Fragment} from 'react';
import {Modal as ModalStyled, ModalClose, ModalContent, ModalFooter, ModalHeader, Overlay, Wrapper} from './styles';
import Portal from "../portal/Portal";
import ModalLoader from "./ModalLoader";

class Modal extends Component {
  static openModalsCount = 0;

  constructor(props) {
    super(props);

    this.state = {
      loader: props.loader,
      show: props.show,
      fade: props.show,
    };
  }

  componentDidMount() {
    if (this.props.show) {
      this.setModalVisible();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setModalVisible();
    } else if (!this.props.show && prevProps.show) {
      this.setModalInvisible();
    }
  }

  componentWillUnmount() {
    if (this.props.show) {
      this.setModalInvisible();
    }
  }

  setModalVisible = () => {
    Modal.openModalsCount += 1;
    this.updateModalZIndex();
    document.body.style.overflow = 'hidden';
    this.setState({ fade: true });
  }

  setModalInvisible = () => {
    Modal.openModalsCount -= 1;
    this.updateModalZIndex();
    document.body.style.overflow = '';

    // Проверка на наличие дочерних элементов перед скрытием overlay
    const modalElement = document.getElementById('modal');
    if (modalElement && modalElement.children.length === 0) {
      modalElement.style.visibility = 'hidden';
    }

    this.setState({ fade: false });
  }

  updateModalZIndex = () => {
    const modalElement = document.getElementById('modal');
    if (modalElement) {
      if (Modal.openModalsCount > 0) {
        modalElement.style.zIndex = '1000';
        modalElement.style.visibility = 'visible';
      } else {
        modalElement.style.zIndex = '';
        modalElement.style.visibility = 'hidden'; // Если нет открытых модальных окон, скрываем overlay
      }
    }
  }

  render() {
    if (this.props.show) {
      if (this.state.loader) {
        return <ModalLoader show={true} />;
      }

      return (
        <Fragment>
          {this.props.trigger}
          <Portal id={'modal'}>
            <Overlay className='modal' show={this.props.show}>
              <Wrapper>
                <ModalStyled id={this.props.id} size={this.props.size} style={this.props.style} onClick={(e) => e.stopPropagation()}>
                  <div>
                    {this.props.children}
                  </div>
                </ModalStyled>
              </Wrapper>
            </Overlay>
          </Portal>
        </Fragment>
      );
    } else {
      return this.props.trigger;
    }
  }
}

export default Modal;

export { Modal, ModalHeader, ModalContent, ModalFooter, ModalClose, ModalLoader };
