import React, {useEffect} from 'react';
import {handleTitle} from "../../../actions/interface";
import {connect} from "react-redux";
import {CenteredWrapper, Container} from '../../../pages/styles';
import {Img, ImgContainer, NotFoundContainer, Text, Title} from './styles';
import {Button} from "finform";
import {Url} from "finhelper";

const NotFound = (props) => {
  useEffect(() => {
    props.handleTitle('Ошибка 404');
  }, []);

  const url = Url.getCurrentUrl();

  return (
    <Container style={{
      margin: url.indexOf('/cabinet') !== -1 ? '0 auto' : '20px auto 0 auto'
    }}>
      <CenteredWrapper>
        <NotFoundContainer>
          <ImgContainer>
            <Img src={require('./images/swan.png')} />
          </ImgContainer>
          <Title>{props.i18n.common.notFound1}</Title>
          <Text>{props.i18n.common.notFound2}</Text>
          <Text>{props.i18n.common.notFound3}</Text>
          <Button className='style1' type='link' to={url.indexOf('/cabinet') !== -1 ? '/cabinet' : '/'}>
            {props.i18n.common.notFound4}
          </Button>
        </NotFoundContainer>
      </CenteredWrapper>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    i18n: state.i18n,
  }),
  { handleTitle }
);

export default enhance(NotFound);