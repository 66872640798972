import React from 'react';
import {connect} from "react-redux";
import {TabWrapper} from "../../../../../components/InnerTabs/styles";
import InnerTab from "../../../../../components/InnerTabs/InnerTab";

const BlockChartInnerTabsChart = ({ style, chartId, onClick, i18n }) => {
  return (
    <TabWrapper style={style}>
      <InnerTab
        id='block-chart-1'
        onClick={() => onClick(1)}
        active={chartId === 1}
      >
        {i18n.incomeOutcome.month}
      </InnerTab>
      <InnerTab
        id='block-chart-2'
        onClick={() => onClick(2)}
        active={chartId === 2}
      >
        {i18n.incomeOutcome.period}
      </InnerTab>
    </TabWrapper>
  );
};

const enhance = connect(
  state => ({
    auth: state.auth.auth,
    data: state.auth.data,
    i18n: state.i18n,
  }),
  {}
);

export default enhance(BlockChartInnerTabsChart);