import {createReducer} from 'redux-act'
import * as actions from '../actions/tactics'
import {BY_MONTH} from "#app/pages/IncomeAndExpenses/Tactics/TacticsFilter";

const prefixCache = 'tactics'

let state = {
  start: localStorage.getItem(prefixCache + '_start') || null,
  end: localStorage.getItem(prefixCache + '_end') || null,
  blockType: localStorage.getItem(prefixCache + '_block_type_id') ? parseInt(localStorage.getItem(prefixCache + '_block_type_id')) : BY_MONTH,
  months: [],
  selectedMonth: null,
  spendingsWithFilter: [],
  spendingsWithFilterLoading: true,
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const handleSetStart = (state, start) => {
  saveToLocalStorage(prefixCache + '_start', start);
  return { ...state, start };
};

const handleSetEnd = (state, end) => {
  saveToLocalStorage(prefixCache + '_end', end);
  return { ...state, end };
};

const handleSetBlockType = (state, blockType) => {
  saveToLocalStorage(prefixCache + '_block_type_id', blockType);
  return { ...state, blockType };
};

const handleSetMonths = (state, months) => {
  saveToLocalStorage(prefixCache + '_months', JSON.stringify(months));
  return { ...state, months };
};

const tacticsSetSelectedMonth = (state, selectedMonth) => {
  saveToLocalStorage(prefixCache + '_selected_month', JSON.stringify(selectedMonth));
  return { ...state, selectedMonth };
};

const handleSpendingsWithFilter = (state, response) => {

  return {
    ...state,
    spendingsWithFilter: response.data,
    spendingsWithFilterLoading: false
  };
}


const reducer = createReducer({
  [actions.tacticsSetStart]: handleSetStart,
  [actions.tacticsSetEnd]: handleSetEnd,
  [actions.tacticsSetBlockType]: handleSetBlockType,
  [actions.tacticsSetMonths]: handleSetMonths,
  [actions.tacticsSetSelectedMonth]: tacticsSetSelectedMonth,
  [actions.handleSpendingsWithFilter]: handleSpendingsWithFilter,
}, state)

export default reducer
