import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Api} from 'laravel-request';
import * as scenarioActions from '../actions/scenario';
import * as authActions from '../actions/auth';
import {handleScenario} from '../sagas/auth';
import {ClientConstants, ClientModel} from 'finhelper';

let isLoading = false;

function* callHelperGotoFunc(action) {
  const state = yield select();
  const step = action.payload?.step;

  if (typeof state.scenario.helperGotoStep === 'function' && step !== undefined) {
    state.scenario.helperGotoStep(step);
  }
}

function* callHelperCloseFunc() {
  const state = yield select();
  if (typeof state.scenario.helperClose === 'function') {
    state.scenario.helperClose();
  }
}

function apiScenarioRequest(endpoint, data) {
  return new Promise((resolve, reject) => {
    Api.post('user-scenario', endpoint, data).call(
      (response) => resolve(response),
      (errors) => {
        console.error(errors);
        reject(errors);
      }
    );
  });
}

function* apiAvatarRequest(endpoint, data) {
  return new Promise((resolve, reject) => {
    Api.get(endpoint, 'avatar', data).call(
      (response) => resolve(response),
      (errors) => {
        console.error(errors);
        reject(errors);
      }
    );
  });
}

function* processScenarioResponse(response, callback) {
  yield put(authActions.handleGetUser(response));
  yield call(handleScenario, response);
  yield put(authActions.handleAuth());
  if (typeof callback === 'function') {
    yield call(callback);
  }
}

function* startScenario(action) {
  try {
    const scenario = action.payload?.scenario;
    const callback = action.payload?.callback;

    if (scenario) {
      const response = yield call(apiScenarioRequest, 'start', { scenario });
      yield call(processScenarioResponse, response, callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* backScenario(action) {
  try {
    const callback = action.payload?.callback;

    const response = yield call(apiScenarioRequest, 'back', {});
    yield call(processScenarioResponse, response, callback);
  } catch (err) {
    console.error(err);
  }
}

function* moveScenario(action) {
  try {
    if (isLoading) return; // Если флаг установлен, не продолжаем выполнение

    isLoading = true; // Устанавливаем флаг перед вызовом API
    const callback = action.payload?.callback;

    const response = yield call(apiScenarioRequest, 'move', {});
    yield call(processScenarioResponse, response, callback);
  } catch (err) {
    console.error(err);
  } finally {
    isLoading = false; // Сбрасываем флаг после завершения
  }
}

function* setStepScenario(action) {
  try {
    const step = action.payload?.step;
    const scenario = action.payload?.scenario;
    const callback = action.payload?.callback;

    if (step !== undefined && scenario) {
      const response = yield call(apiScenarioRequest, 'set', { step, scenario });
      yield call(processScenarioResponse, response, callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* finishScenario(action) {
  try {
    const scenario = action.payload?.scenario;
    const callback = action.payload?.callback;

    if (scenario) {
      const response = yield call(apiScenarioRequest, 'finish-any', { scenario });
      document.body.style.overflow = 'visible';
      yield call(processScenarioResponse, response, callback);
      yield put(scenarioActions.handleEndTutorial());
    }
  } catch (err) {
    console.error(err);
  }
}

function* getFormAvatar() {
  try {
    const state = yield select();
    const scenarioId = state.scenario?.data?.id;

    if (scenarioId) {
      const response = yield call(apiAvatarRequest, 'profile', { id: scenarioId });
      yield put(scenarioActions.handleFormAvatar(response.data));
    }
  } catch (err) {
    console.error(err);
  }
}

function* getFormData(action) {
  try {
    const state = yield select();
    const callback = action.payload?.callback;

    const { data } = state.auth || {};
    const { role } = state.auth?.data || {};

    if (data) {
      if (role === ClientConstants.CLIENT) {
        yield put(scenarioActions.handleFormData(data));

        yield put(scenarioActions.handleFormAvatar(data));
        if (callback) yield call(callback);
      } else if (
        [ClientConstants.MANAGER, ClientConstants.OWNER, ClientConstants.DEPARTMENT_BOSS, ClientConstants.DIRECTOR].includes(role)
      ) {
        const dataManager = yield new Promise((resolve) => {
          ClientModel.fetch().orderBy('id', 'ASC').all((response) => {
            const client = JSON.parse(localStorage.getItem('client')) || response.data[0];
            resolve(response.data.find((item) => item.id === client?.id) || response.data[0]);
          });
        });

        if (dataManager) {
          yield put(scenarioActions.handleFormData(dataManager));
          yield put(scenarioActions.handleFormAvatar(dataManager));
        }
        if (callback) yield call(callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* watcher() {
  yield takeLatest(scenarioActions.startScenario, startScenario);
  yield takeLatest(scenarioActions.setStepScenario, setStepScenario);
  yield takeLatest(scenarioActions.backScenario, backScenario);
  yield takeLatest(scenarioActions.moveScenario, moveScenario);
  yield takeLatest(scenarioActions.finishScenario, finishScenario);
  yield takeLatest(scenarioActions.getFormData, getFormData);
  yield takeLatest(scenarioActions.getFormAvatar, getFormAvatar);
  yield takeLatest(scenarioActions.callHelperGotoFunc, callHelperGotoFunc);
  yield takeLatest(scenarioActions.callHelperCloseFunc, callHelperCloseFunc);
}
