import React from 'react';
import HeaderLanding from '../interface/landing/header/Header';
import Layout from './Layout/Layout';

const LandingLayout = () => (
  <>
    <HeaderLanding />
    <Layout />
  </>
);

export default LandingLayout;