import React from 'react';
import {Container} from './styles';

function InnerTab({ id, style, href, onClick, className, active, children }) {
  return (
    <Container
      id={id}
      style={style}
      href={href}
      onClick={onClick}
      className={`${className || ''}${active ? ' active' : ''}`}
    >
      {children}
    </Container>
  );
}

export default InnerTab;
